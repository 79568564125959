import "../App.css";
import {
  submitInitialCompanyInfo,
} from "../endpoints.js";
import { useState } from "react";
import { Dna } from "react-loader-spinner";

const Admin = () => {
  const [inputs, setInputs] = useState({
    users: [
      {
        name: "",
        email: "",
      },
      {
        name: "",
        email: "",
      },
    ],
  });
  const [emails, setEmails] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleUserInfoChange = (event, userIndex) => {
    const updatedUsers = [...inputs.users];
    updatedUsers[userIndex][event.target.name] = event.target.value;
    setInputs((values) => ({ ...values, users: updatedUsers }));
  };

  const handleSubmitCompanyForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const emails = await submitInitialCompanyInfo(inputs).then(
        (response) => response.emails
      );
      setEmails(emails);
    } catch (e) {
      alert(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="w-50">
        <form onSubmit={handleSubmitCompanyForm}>
          <div className="mb-3">
            <label className="form-label" forhtml="inputCompanyName">
              Company name:
            </label>
            <input
              type="text"
              className="form-control"
              name="companyName"
              placeholder="Company Name"
              value={inputs.companyName || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" forhtml="inputCompanyUrl">
              Company website:
            </label>
            <input
              type="url"
              className="form-control"
              name="companyWebsite"
              placeholder="Company Url"
              value={inputs.companyWebsite || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" forhtml="inputCompanyDescription">
              Company description:
            </label>
            <textarea
              className="form-control"
              rows="5"
              name="companyDescription"
              placeholder="Company Description"
              value={inputs.companyDescription || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" forhtml="inputContactName">
              Contact 1 name:
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Contact Name"
              value={inputs.users[0].name || ""}
              onChange={(e) => handleUserInfoChange(e, 0)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" forhtml="inputContactEmail">
              Contact 1 email:
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Contact Email"
              value={inputs.users[0].email || ""}
              onChange={(e) => handleUserInfoChange(e, 0)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" forhtml="inputContactName">
              Contact 2 name:
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Contact Name"
              value={inputs.users[1].name || ""}
              onChange={(e) => handleUserInfoChange(e, 1)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" forhtml="inputContactEmail">
              Contact 2 email:
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Contact Email"
              value={inputs.users[1].email || ""}
              onChange={(e) => handleUserInfoChange(e, 1)}
            />
          </div>
          <button className="btn btn-primary">
            Create model for my business
          </button>
          <div className="ml-4">
            <Dna
              visible={submitting}
              height="60"
              width="60"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        </form>
        {emails && <h3 className="mt-4">Marketing Emails:</h3>}
        <div className="whitespace-pre-wrap mt-4">{emails}</div>
      </div>
    </div>
  );
};

export default Admin;
