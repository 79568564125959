import "../App.css";
import { generateReport, getUserDetail } from "../endpoints.js";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Products from "./Products";
import BrandIdentityReport from "./BrandIdentityReport";
import { Dna } from "react-loader-spinner";

const ClientApp = () => {
  const [user, setUser] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [product, setProduct] = useState(null);

  const params = useParams();
  const userId = params.id;

  const handleGenerateReport = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const reportResponse = await generateReport(user.company).then(
        (response) => response.report
      );
      setProduct(<BrandIdentityReport report={reportResponse} />);
    } catch (e) {
      alert(e);
    } finally {
      setSubmitting(false);
    }
  };

  const launchStrategyExplorer = () => {
    if (submitting) {
      return;
    }
    console.log('launchStrategyExplorer')
  };

  useEffect(() => {
    const setUserDetail = async () => {
      const userResponse = await getUserDetail(userId);
      setUser(userResponse);
    };
    setUserDetail();
  }, [userId]);

  return (
    <div>
      {user.company && (
        <div className="my-4">
          <div className="w-full text-8xl flex items-center justify-center font-semibold mb-4 text-center">
            {user.company?.companyName}
          </div>
          <div className="w-full text-xl flex items-center justify-center font-medium mb-4 text-center">
            {user.company?.companyDescription}
          </div>
          <div className="w-full flex justify-center">
            <button className="btn btn-outline-secondary">
              {user.company?.companyWebsite}
            </button>
          </div>
          <div className="mt-10">
            <Products
              generateReport={handleGenerateReport}
              launchStrategyExplorer={launchStrategyExplorer}
            />
          </div>
          {submitting && (
            <div className="mt-8 w-full flex items-center justify-center">
              <div className="font-medium whitespace-pre-wrap text-center text-xs">
                <div className="w-full flex items-center justify-center">
                  <Dna
                    height="60"
                    width="100"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
                {`utilizing custom ai model\nbuilt for ${user.name}...`}
              </div>
            </div>
          )}
          {!submitting && product && <div>{product}</div>}
        </div>
      )}
    </div>
  );
};

export default ClientApp;
