let hostUrl;
switch (process.env.NODE_ENV) {
  case "production":
    hostUrl = "https://viiibe-core-api.onrender.com";
    break;
  default:
    hostUrl = "http://localhost:8080";
    break;
}

async function submitInitialCompanyInfo(companyInfo) {
  const payload = Object.assign({}, companyInfo);
  payload.users = [];
  for (const user of companyInfo.users) {
    if (user.name && user.email) {
      payload.users.push(user);
    }
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${hostUrl}/submitInitialCompanyInfo`,
    requestOptions
  ).then((response) => response.json());
}

async function generateReport(companyInfo) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(companyInfo),
  };
  return await fetch(`${hostUrl}/generateReport`, requestOptions).then(
    (response) => response.json()
  );
}

async function generateEmails(companyInfo) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(companyInfo),
  };
  return await fetch(`${hostUrl}/generateEmails`, requestOptions).then(
    (response) => response.json()
  );
}

async function getAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  };
  return await fetch(`${hostUrl}/getAllUsers`, requestOptions).then(
    (response) => response.json().users
  );
}

async function getUserDetail(userId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  };
  return await fetch(`${hostUrl}/userDetail/${userId}`, requestOptions)
    .then((response) => response.json())
    .then((json) => json.user);
}

export {
  submitInitialCompanyInfo,
  getAllUsers,
  generateReport,
  generateEmails,
  getUserDetail,
};
