const BrandIdentityReport = ({ report }) => {
  console.log("BIR report: ", report);

  return (
    !!report && (
      <div className="whitespace-pre-wrap m-12 p-4 bg-white border-2 border-solid border-black rounded-lg">
        <div className="text-5xl font-bold mb-4">Brand Identity Report</div>
        <div className="text-4xl font-bold border-1 bg-slate-100 p-2 border-solid border-black rounded-lg">
          {report.companyInfo.companyName}
        </div>
        <div className="text-m font-semibold">{report.date}</div>
        {report.report.map((section) => {
          return (
            <div className="mt-4">
              <div className="text-3xl font-semibold italic">
                {section.type}
              </div>
              <div className="text-xl font-semibold">{section.description}</div>
              {section.content.map((content) => {
                return (
                  <div>
                    {section.content.length > 1 && (
                      <div className="text-l font-semibold italic mt-4">
                        {content.heading}
                      </div>
                    )}
                    <div className="text-m font-medium -mt-8">
                      {content.aiResponse}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    )
  );
};

export default BrandIdentityReport;
