const Products = ({ generateReport, launchStrategyExplorer }) => {
  const products = [
    {
      productId: "report",
      label: "Generate Brand Identity Report",
      submitting: false,
      classes: 'bg-gradient-to-r from-indigo-50 via-purple-50 to-sky-50',
      action: () => generateReport(),
    },
  ];

  return (
    <div className="w-full flex justify-between">
      {products.map((product, index) => {
        return (
          <div
            key={`product-${index}`}
            className={`h-60 shadow flex grow items-center justify-center transition hover:cursor-pointer hover:-translate-y-1 border-2 border-solid border-white rounded-lg ${product.classes}`}
            onClick={() => product.action(product)}
          >
            <div
              key={`product-label-${index}`}
              className="w-full text-3xl font-semibold text-center p-4"
            >
              {product.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Products;
