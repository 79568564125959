const Splash = () => {
  return (
    <div className="p-10">
      <div className="w-full text-9xl flex items-center justify-center font-semibold mb-10 text-center">
        🏄
      </div>
      <div className="w-full text-8xl flex items-center justify-center font-semibold mb-4 text-center">
        Ai for business made easy
      </div>
      <div className="w-full text-xl flex items-center justify-center font-medium mb-4 text-center">
        Check out our suite of easy to use, high powered ai tools custom fit to
        your business
      </div>
    </div>
  );
};

export default Splash;
