import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import ClientApp from "./components/ClientApp";
import Admin from "./components/Admin";
import NavBar from "./components/NavBar";
import About from "./components/About";
import Splash from "./components/Splash";

const App = () => {
  const navigate = useNavigate();

  const selectNavBarItem = (item) => {
    if (item.type === "route") {
      navigate(item.route);
    }
  };

  return (
    <div>
      <NavBar selectNavBarItem={selectNavBarItem} />
      <div className="px-4 py-4 min-h-screen bg-amber-50">
        <Routes>
        <Route path="/" element={<Splash />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/user/:id" element={<ClientApp />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
