const NavBar = ({ selectNavBarItem }) => {
  const navBarItems = [
    {
      label: "About",
      type: "route",
      route: 'about',
      action: (e) => selectNavBarItem(e),
      className: "btn btn-outline-info mr-5",
    },
    {
      label: "Sign Up",
      type: "action",
      action: (e) => selectNavBarItem(e),
      className: "btn h-10 font-bold",
    },
  ];

  return (
    <div>
      <div className="h-24 w-full">
        <div className="fixed h-24 w-full border-b border-black bg-white flex">
          <div className="h-full flex items-center px-6 whitespace-nowrap">
            <div>
              <div className="text-4xl font-black tracking-tighter italic">
                Viiibe
              </div>
            </div>
          </div>
          <div className="h-full w-full flex justify-end items-center px-6">
            {navBarItems.map((item, index) => {
              return (
                <button
                  key={`nav-bar-item-${index}`}
                  className={item.className}
                  onClick={() => item.action(item)}
                >
                  {item.label}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="fixed top-20 w-full flex items-center justify-end">
        <div className="bg-white px-2 italic mr-20 shadow-sm">ai made easy</div>
      </div>
    </div>
  );
};

export default NavBar;
